class GTag {
  gtagUrlPath = "https://www.googletagmanager.com/gtag/js?id=";
  gaMeasurementId = "";
  gaOptions = {
    sendPageView: true
  };

  constructor(gaMeasurementId, options = {}) {
    window.dataLayer = window.dataLayer || [];
    this.gaMeasurementId = gaMeasurementId;
    this.gaOptions = {
      ...this.gaOptions,
      ...options
    };
    this.setUpGA();
  }

  setUpGA() {
    try {
      this.loadScript();
      const sendPageView = !!this.gaOptions.sendPageView;
      GTag.gtag("js", new Date());
      GTag.gtag("config", this.gaMeasurementId, { send_page_view: sendPageView });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Unable to set up GA. ${e}`);
    }
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `${this.gtagUrlPath}${this.gaMeasurementId}`;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  static gtag() {
    // @see: https://redmine2.tyrellsys.com/issues/82849#note-5
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }
}

export { GTag };
