import { GA_MEASUREMENT_ID } from "../constants";
import { GTag } from "../components/gtag";
import { Navigation } from "./navigation";

const splashId = "splash";
const headerId = "header";
const headerLogoId = "headerLogo";
const navigationId = "navigation";
const mainId = "main";
const defaultSectionId = splashId;
let $bodyElement = null;

class Home {
  scrollTimeout = null;
  scrollTimeoutDelay = 100;
  scrollMarkApproximateValue = 5;
  winHeight = window.innerHeight;
  winScrollY = window.scrollY;
  minSplashHeight = 400;
  $splashElement = null;
  $headerElement = null;
  $mainElement = null;
  $navElement = null;
  $navLinks = null;

  constructor() {
    this.$splashElement = document.querySelector(`[data-id="${splashId}"]`);
    this.$headerElement = document.querySelector(`[data-id="${headerId}"]`);
    this.$mainElement = document.querySelector(`[data-id="${mainId}"]`);
    this.$navElement = document.querySelector(`[data-id="${navigationId}"]`);

    if (this.$splashElement) {
      this.setUpSplash();
    }
    if (this.$navElement) {
      this.setUpNavigation();
    }

    window.addEventListener("resize", () => this.resizeHandler(), false);
    window.addEventListener("scroll", () => this.scrollHandler(), false);
    this.resizeHandler();
    Home.goToSection();
  }

  setUpSplash() {
    const $splashScrollElement = this.$splashElement.querySelector(`[data-id="splashScroll"]`);
    $splashScrollElement.addEventListener("click", (e) => {
      e.preventDefault();
      const { urlPath } = e.target.dataset;
      Home.scrollToSection(urlPath);
    });
  }

  setUpNavigation() {
    const $headerLogoElement = this.$headerElement.querySelector(`[data-id="${headerLogoId}"]`);
    this.$navLinks = this.$navElement.querySelectorAll("a");

    $headerLogoElement.addEventListener("click", (e) => {
      e.preventDefault();
      Home.scrollToSection(defaultSectionId);
    });

    [...this.$navLinks].forEach(($link) => {
      $link.addEventListener("click", (e) => {
        const { urlPath } = $link.dataset;
        if (urlPath) {
          e.preventDefault();
          Home.scrollToSection(urlPath);
        }
      });
    });
  }

  resizeHandler() {
    this.winHeight = window.innerHeight;
    this.$splashElement.style.height = `${Math.max(this.minSplashHeight, this.winHeight)}px`;
  }

  scrollHandler() {
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.highlightNavigation();
    }, this.scrollTimeoutDelay);

    this.winScrollY = window.scrollY;
    if (this.winScrollY > this.winHeight) {
      this.$headerElement.classList.add("header--fixed");
      this.$mainElement.classList.add("top-padded");
    } else {
      this.$headerElement.classList.remove("header--fixed");
      this.$mainElement.classList.remove("top-padded");
    }
  }

  highlightNavigation() {
    if (this.$navLinks) {
      let $activeLink = null;
      let currentPath = "";
      [...this.$navLinks].forEach(($link) => {
        const { urlPath } = $link.dataset;
        if (urlPath) {
          const $targetSectionElement = document.querySelector(`[data-id="${urlPath}"]`);
          const topPos = $targetSectionElement.getBoundingClientRect().top + window.scrollY;
          if (this.winScrollY > topPos - this.scrollMarkApproximateValue) {
            $activeLink = $link;
            currentPath = urlPath;
          }
        }
      });
      [...this.$navLinks].forEach(($link) => {
        const $liElement = $link.closest("li");
        if ($link !== $activeLink) {
          $liElement.classList.remove("current");
        } else {
          $liElement.classList.add("current");
        }
      });
      Home.setBrowserUrl(currentPath);
    }
  }

  static setBrowserUrl(pageId) {
    const [currentHref] = window.location.href.split("#");
    if (pageId) {
      window.history.pushState({}, "", `${currentHref}#${pageId}`);
      return;
    }
    window.history.pushState({}, "", currentHref);
  }

  static scrollToSection(id) {
    Navigation.closeNavigation();

    const $targetSectionElement = document.querySelector(`[data-id="${id}"]`);
    $targetSectionElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  static goToSection() {
    const currentHash = window.location.hash;
    let pageId = defaultSectionId;
    if (currentHash) {
      [, pageId] = currentHash.split("#");
    }

    const $targetSectionElement = document.querySelector(`[data-id="${pageId}"]`);
    $targetSectionElement.scrollIntoView();
    $bodyElement.style.opacity = 1;
  }
}

window.addEventListener(
  "DOMContentLoaded",
  () => {
    $bodyElement = document.querySelector("body");
    $bodyElement.style.opacity = 0;
  },
  false
);
window.addEventListener(
  "load",
  () => {
    /* eslint-disable no-unused-vars */
    const home = new Home();
    const navigation = new Navigation();
    const gtag = new GTag(GA_MEASUREMENT_ID);
    /* eslint-enable no-unused-vars */
  },
  false
);
